<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="3">
        <!-- INDICE DE CANALES -->
        <channel-index
          :loading="indexLoading"
          :selected="channel"
          :createMode="true"
          :edit-mode="true"
          @select-channel="handlerChangeChannel"
          @add-channel="handlerCreateChannel"
          @edit-channel="handlerEditChannel"
        />
      </v-col>
      <v-col cols="12" md="9">
        <v-slide-x-transition mode="out-in">
          <section v-if="channel !== ''" class="custom-border">
            <v-tabs
              @change="handlerTabChange"
              v-model="tab"
              show-arrows
              :center-active="isMobile"
            >
              <v-tab
                v-for="(tabHeader, i) in tabs"
                :key="i"
                class="text-capitalize"
                :disabled="tabHeader.disabled"
                >{{ i + 1 }}. {{ tabHeader.name }}</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="currentTab" touchless>
              <v-tab-item
                v-for="(tabItem, i) in tabs"
                :key="i"
                :class="['py-6 form-box', isMobile ? 'px-2' : 'px-12']"
              >
                <component
                  v-if="i == currentTab"
                  :is="tabItem.component"
                  :ref="tabItem.ref"
                  :channelName="channelName"
                  :panel-classes="panelClass"
                  :mobile="isMobile"
                  :channel="channel"
                  @change="handlerComponentChange"
                ></component>
              </v-tab-item>
            </v-tabs-items>
          </section>
          <div v-else class="fill-height">
            <h4 class="grey--text text--darken-1 text-center title">
              Por favor seleccione un canal o sucursal...
            </h4>
          </div>
        </v-slide-x-transition>
        <div
          class="text-center mt-6 mb-3"
          v-if="channel !== '' && submitBtn.show"
        >
          <v-btn
            color="primary"
            @click="handlerSaveData"
            :loading="submitBtn.loading"
            :disabled="submitBtn.disabled"
            >Guardar {{ getTabTitle() }}</v-btn
          >
        </div>
        <v-slide-y-transition>
          <p class="error--text caption text-center" v-if="error">
            Hay campos en el formulario que requieren de tu atención.
          </p>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <base-modal
      v-if="channelForm.show"
      v-model="channelForm.show"
      :persistent="channelForm.loading || channelForm.deleteLoading"
      @close="handlerCloseChannelForm()"
    >
      <div class="pa-6">
        <channel-form
          :title="`${editItem ? 'Actualizar' : 'Crear'} un Canal o Sucursal`"
          :channels="GetAllChannelList"
          :selectedChannel="channel"
          :loading="channelForm.loading"
          :deleteLoading="channelForm.deleteLoading"
          :form-data="editItem"
          @save="handlerSaveForm"
          @remove="handlerRemoveChannel"
        />
      </div>
    </base-modal>
    <confirm-modal ref="confirmDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralInfoForm from "@/components/Forms/business/generalInfoForm.vue";
import AdvanceInfoForm from "@/components/Forms/business/advanceInfoForm.vue";
import AdvanceConfigForm from "@/components/Forms/business/advanceConfigForm.vue";
import ProductForm from "@/components/Forms/business/productForm.vue";
import AditionalProductForm from "@/components/Forms/business/aditionalProductForm.vue";
import UserForm from "@/components/Forms/business/userForm.vue";

export default {
  name: "ChannelFeaturePage",
  components: {
    ChannelIndex: () => import("@/components/Business/channelIndex.vue"),
    BaseModal: () => import("@/components/Modal/BaseModal.vue"),
    ConfirmModal: () => import("@/components/Modal/ConfirmModal.vue"),
    ChannelForm: () => import("@/components/Forms/business/channelForm.vue"),
  },
  data: () => ({
    page: "03",
    channel: "",
    channelName: undefined,
    tab: 0,
    currentTab: 0,
    tabWihoutSubmit: [6],
    submitBtn: {
      show: true,
      loading: false,
      disabled: false,
    },
    userFormAction: false,
    indexLoading: false,
    channelForm: {
      show: false,
      loading: false,
      deleteLoading: false,
    },
    error: false,
    editItem: null,
  }),
  async created() {
    this.$store.commit("setPage", { page: this.page });
    await this.setChannels();
  },
  computed: {
    ...mapGetters("Channels", [
      "GetChannels",
      "GetAllChannelList",
      "GetChannelMessages",
    ]),

    feature() {
      return this.$route.query?.feature;
    },
    panelClass() {
      return this.$store.state.panelClass;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tabs() {
      return [
        {
          id: 1,
          name: "Información general",
          ref: "generalInfo",
          component: GeneralInfoForm,
          method: this.SEND_CHANNEL_GENERAL_INFO,
          disabled: false,
        },
        {
          id: 2,
          name: "Información avanzada",
          ref: "advanceInfo",
          component: AdvanceInfoForm,
          method: this.SEND_CHANNEL_ADVANCE_INFO,
          disabled: false,
        },
        {
          id: 3,
          name: "Configuración avanzada",
          ref: "advanceConfig",
          component: AdvanceConfigForm,
          method: this.SEND_CHANNEL_ADVANCE_CONFIG,
          disabled: false,
        },
        {
          id: 4,
          name: "Productos",
          ref: "products",
          component: ProductForm,
          method: this.SEND_CHANNEL_PRODUCTS,
          disabled: false,
        },
        {
          id: 5,
          name: "Productos adicionales",
          ref: "aditionalProduct",
          component: AditionalProductForm,
          method: null,
          disabled: true,
        },
        {
          id: 6,
          name: "Campañas y potenciadores",
          ref: "campaignAndEnhancer",
          component: null,
          method: null,
          disabled: true,
        },
        {
          id: 7,
          name: "Usuarios Modulos de Ventas",
          ref: "users",
          component: UserForm,
          method: null,
          disabled: false,
        },
      ];
    },
    tabObject() {
      return this.tabs.find((e, i) => i === this.currentTab);
    },
  },
  methods: {
    // STORE ACTIONS
    ...mapActions("Channels", [
      "REQUEST_CHANNELS",
      "CREATE_CHANNEL",
      "EDIT_CHANNEL",
      "DELETE_CHANNEL",

      "SEND_CHANNEL_GENERAL_INFO",
      "SEND_CHANNEL_ADVANCE_INFO",
      "SEND_CHANNEL_ADVANCE_CONFIG",
      "SEND_CHANNEL_PRODUCTS",
    ]),
    // HANDLER METHODS
    handlerCloseChannelForm() {
      this.channelForm.show = false;
      this.editItem = null;
    },
    // Acciones que se ejecutan antes de cambiar de pestaña
    async handlerTabChange() {
      // Mostrar ventana de confirmacion...
      // en caso de que se haya modificado algo en el formulario de la pestaña actual.
      if (this.userFormAction) {
        await this.$refs.confirmDialog
          .show({
            title: this.GetChannelMessages.switch.segment.title,
            description: this.GetChannelMessages.switch.segment.description,
          })
          .then((result) => {
            this.tabChange(result);
          });
      } else {
        await this.tabChange(true);
      }
      // Ocultar o mostrar el boton de Guardar para el formulario de una pestaña en especifico.
      if (this.tabWihoutSubmit.includes(this.currentTab)) {
        this.submitBtn.show = false;
      } else {
        this.submitBtn.show = true;
      }
      // En caso de que haya un error activo, se oculta
      if (this.error) {
        this.error = false;
      }
    },
    tabChange(change) {
      change;
      if (change) {
        this.currentTab = this.tab;
        this.userFormAction = false;
        if (this.tab + 1 != this.feature) {
          this.$router.replace({
            path: this.$route.path,
            query: { feature: `${this.tab + 1}` },
          });
        }
      } else {
        this.tab = this.currentTab;
      }
    },
    async handlerSetTabByFeature() {
      if (this.feature) {
        const currentTab = parseInt(this.feature - 1, 10);
        const comparedTab = this.tabs.find((e) => e.id == this.feature);
        if (comparedTab) {
          if (!comparedTab.disabled) {
            this.tab = currentTab;
            this.tabChange(true);
          } else {
            this.snackbar(
              "Sección de configuración de canal deshabilitada.",
              "warning"
            );
          }
        } else {
          this.snackbar(
            "No se ha encontrado la sección de configuración del canal."
          );
        }
      }
    },
    handlerChangeChannel(channel) {
      if (this.channel === "") {
        this.channel = channel;
      } else {
        if (this.userFormAction) {
          this.$refs.confirmDialog
            .show({
              title: this.GetChannelMessages.switch.title,
              description: this.GetChannelMessages.switch.description,
            })
            .then((result) => {
              if (result) {
                this.changeChannel(channel);
              }
            });
        } else {
          this.changeChannel(channel);
        }
      }
    },
    async changeChannel(channel = null) {
      if (channel) {
        await this.selectChannel(channel);
        await this.$refs[this.tabObject.ref][0].setup();
        this.userFormAction = false;
      } else {
        this.snackbar("No se ha encontrado el canal solicitado.");
      }
    },
    handlerCreateChannel() {
      this.channelForm.show = true;
    },
    handlerEditChannel(item) {
      this.editItem = item;
      this.channelForm.show = true;
    },
    handlerSaveData() {
      const tab = this.tabs.find((i, index) => index === this.currentTab);
      const subComponent = this.$refs[tab.ref][0];
      subComponent.$refs.observer.validate().then((result) => {
        if (result) {
          this.error = false;
          this.$refs.confirmDialog
            .show({
              title: this.GetChannelMessages[tab.ref].save,
              description: this.GetChannelMessages[tab.ref].saveDescription,
            })
            .then((result) => {
              if (result) {
                this.submitBtn.loading = true;
                subComponent.loading = true;

                tab
                  .method({
                    data: subComponent.form,
                    channel: this.channel,
                    upChannel: this.getUpperLevelChannel(this.channel),
                  })
                  .then((message) => {
                    this.snackbar(message, "success");
                    this.userFormAction = false;
                  })
                  .catch((error) => {
                    this.snackbar(
                      error?.message || error,
                      error?.type || "error"
                    );
                  })
                  .finally(() => {
                    this.submitBtn.loading = false;
                    subComponent.loading = false;
                  });
              }
            });
        } else {
          this.error = true;
        }
      });
    },
    handlerComponentChange() {
      this.userFormAction = true;
    },
    selectChannel(channel) {
      const channelName = this.GetAllChannelList.find((e) => e.id == channel);
      this.channelName = channelName?.description;
      this.channel = channel;
    },
    getTabTitle() {
      const item = this.tabObject;
      return item ? item.name : "";
    },
    setChannels() {
      this.indexLoading = true;
      this.REQUEST_CHANNELS()
        .then(async () => {
          // Seleccionando el primer canal de la lista
          if (this.GetChannels.length > 0) {
            this.selectChannel(this.GetChannels[0]._id);
          }
          await this.handlerSetTabByFeature();
          this.indexLoading = false;
        })
        .catch((message) => {
          this.indexLoading = false;
          this.snackbar(message);
        });
    },
    handlerSaveForm(form) {
      this.editItem ? this.editChannel(form) : this.createChannel(form);
    },
    handlerRemoveChannel(channelId) {
      this.$refs.confirmDialog
        .show({
          title: this.GetChannelMessages.delete.confirmTit,
          description: this.GetChannelMessages.delete.confirmDesc,
        })
        .then((result) => {
          if (result) {
            this.deleteChannel(channelId);
          }
        });
    },
    createChannel(data) {
      this.channelForm.loading = true;
      this.CREATE_CHANNEL(data)
        .then((response) => {
          this.changeChannel(response.result?._id);
          this.snackbar(response.info, "success");
          this.channelForm.show = false;
        })
        .catch((response) => {
          this.snackbar(response.message, response.type);
        })
        .finally(() => (this.channelForm.loading = false));
    },

    editChannel(data) {
      this.channelForm.loading = true;
      const payload = {
        formData: data,
        id: this.editItem._id,
      };
      this.EDIT_CHANNEL(payload)
        .then((message) => {
          this.snackbar(message, "success");
          this.handlerCloseChannelForm();
        })
        .catch((response) => {
          this.snackbar(response.message, response.type);
        })
        .finally(() => (this.channelForm.loading = false));
    },

    deleteChannel(data) {
      this.channelForm.deleteLoading = true;
      this.DELETE_CHANNEL(data)
        .then((message) => {
          this.snackbar(message, "success");
          this.handlerCloseChannelForm();
        })
        .catch((response) => {
          this.snackbar(response.message, response.type);
        })
        .finally(() => (this.channelForm.deleteLoading = false));
    },

    getUpperLevelChannel(channel = "") {
      return (
        this.GetAllChannelList.find((e) => e.id === channel)?.dependId ?? ""
      );
    },
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
};
</script>

<style scoped>
.custom-border {
  border: solid 1px rgba(0, 0, 0, 0.2);
}
</style>
